
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout 竖向*/
import Layout from '@/views/layout/Layout'
/* abeam 横向*/
import Abeam from '@/views/abeam/Layout'


export const routerMap = {
  Layout: () => Layout,

}
export const constantRouterMap = [
  //首次加载时先走登录页
  {
    path: '/login',
    name: 'login',
    component: () => {
      return import("@/views/login/index")
    }
  },

  //路由重定向
  {
    path: '/',
    redirect: "/login"
  },
  /* {
    path: '/',
    redirect: "/home"
  }, */
  // 没有下拉菜单，页面卸载children中
  {
    path: '/homeView',
    component: Abeam,
    redirect: 'home',
    name: 'homeView',
    alwaysShow: true,
    meta: {
      title: 'Home',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/home',
        component: () => import('@/views/pages/home'),
        name: 'home',
        meta: { title: 'Home', title1: '首页', }
      },
    ]
  },
  {
    path: '/Omics',
    component: Abeam,
    redirect: 'Omics',
    name: 'Omics',
    alwaysShow: true,
    meta: {
      title: 'Omics',//标题
      title1: '组学',
      icon: 'search'//图标
    },
    children: [
      {
        path: '/Omics',
        component: () => import('@/views/pages/omics/Omics'),
        // redirect:"/Omics",
        name: 'Omics',
        meta: { title: 'Omics', title1: '组学', }
      },
      /* {
        path: '/Genomes',
        component: () => import('@/views/pages/Genomes'),
        redirect:"/Genomes",
        name: 'Genomes',
        meta: { title: 'Genomes', title1: '基因组学',}
      },
      {
        path: '/Transcriptomics',
        component: () => import('@/views/pages/Transcriptomics'),
        name: 'Transcriptomics',
        meta: { title: 'Transcriptomics', title1: '转录组学',icon: 'pathway'}
      },{
        path: '/Proteomics',
        component: () => import('@/views/pages/Proteomics'),
        name: 'Proteomics',
        meta: { title: 'Proteomics',title1: '蛋白组学', icon: 'te'}
      },{
        path: '/Metabolomics',
        component: () => import('@/views/pages/Metabolomics'),
        name: 'Metabolomics',
        meta: { title: 'Metabolomics',title1: '代谢组学', icon: 'te'}
      },{
        path: '/Epigenomics',
        component: () => import('@/views/pages/Epigenomics'),
        name: 'Epigenomics',
        meta: { title: 'Epigenomics',title1: '表观基因组学', icon: 'te'}
      } */
    ]
  },
  {
    path: '/VariationView',
    component: Abeam,
    redirect: 'Statistics',
    name: 'VariationView',
    alwaysShow: true,
    meta: {
      title: 'Variations',//标题
      title1: '变异',//标题
      icon: 'blast'//图标
    },
    children: [
      {
        path: '/Statistics',
        component: () => import('@/views/pages/variations/statics'),
        name: 'Statistics',
        meta: { title: 'Statistics', title1: '统计', icon: 'search1' }
      },
      {
        path: '/SNP-Seek',
        component: () => import('@/views/pages/variations/SNP-Seek'),
        name: 'SNP-Seek',
        meta: { title: 'SNP-Seek', title1: '恒久性变异', icon: 'search1' }
      },
      {
        path: '/Ex-Seq',
        component: () => import('@/views/pages/variations/Ex-Seq'),
        name: 'Ex-Seq',
        meta: { title: 'Ex-Seq', title1: '序列提取', icon: 'gwas1' }
      },
      {
        path: '/GWAS',
        component: () => import('@/views/pages/variations/GWAS'),
        name: 'GWAS',
        meta: { title: 'GWAS', title1: '变异查询', icon: 'qtl' }
      },
    ]
  },
  {
    path: '/AnalysisView',
    component: Abeam,
    redirect: 'GeneCoExpression',
    name: 'AnalysisView',
    alwaysShow: true,
    meta: {
      title: 'Analysis',//标题
      title1: '基因组学',//标题
      icon: 'browse'//图标
    },
    children: [
      {
        path: '/GeneCoExpression',
        component: () => import('@/views/pages/analysis/GeneCoExpression'),
        name: 'Gene Co-expression',
        meta: { title1: '基因共表达', title: 'Gene Co-expression', icon: 'expression' }
      },
      {
        path: '/Deseq2',
        component: () => import('@/views/pages/analysis/Deseq2'),
        name: 'Deseq2',
        meta: { title1: '差异基因分析', title: 'Deseq2', icon: 'deseq2' }
      },
      {
        path: '/PhylogeneticAnalysis',
        component: () => import('@/views/pages/analysis/PhylogeneticAnalysis'),
        name: 'Phylogenetic Analysis',
        meta: { title1: '进化分析', title: 'Phylogenetic Analysis', icon: 'analysis' }
      },
      {
        path: '/WGCNA',
        component: () => import('@/views/pages/analysis/WGCNA'),
        name: 'Wgcna',
        meta: { title1: '权重基因共表达网络分析', title: 'WGCNA', icon: 'wgcan' }
      },
      /* {
        path: '/EpigeneticRegulation',
        component: () => import('@/views/pages/analysis/EpigeneticRegulation'),
        name: 'Epigenetic regulation',
        meta: { title1: '权重基因共表达网络分析',title:'Epigenetic regulation' ,icon: 'wgcan'}
      }, */
    ]
  },
  {
    path: '/NetworkView',
    component: Abeam,
    redirect: 'GeneCoExpression',
    name: 'NetworkView',
    alwaysShow: true,
    meta: {
      title: 'Network',//标题
      title1: '网络图',//标题
      icon: 'browse'//图标
    },
    children: [
      {
        path: '/Net1',
        component: () => import('@/views/pages/Network/Net1'),
        name: 'net1',
        meta: { title1: 'net1', title: 'net1', icon: 'expression' }
      },
      {
        path: '/Net2',
        component: () => import('@/views/pages/Network/Net2'),
        name: 'net2',
        meta: { title1: 'net2', title: 'net2', icon: 'expression' }
      },
      {
        path: '/Netbest',
        component: () => import('@/views/pages/Network/Netbest'),
        name: 'netbest',
        meta: { title1: 'net best', title: 'net best', icon: 'expression' }
      },
    ]
  },
  {
    path: '/toolsView',
    component: Abeam,
    redirect: 'Blast',
    name: 'toolsView',
    alwaysShow: true,
    meta: {
      title: 'Tools',//标题
      title1: '工具',
      icon: 'tools'//图标
    },
    children: [
      {
        path: '/Blast',
        component: () => import('@/views/pages/tools/Blast'),
        name: 'Blast',
        meta: { title: 'Blast', title1: '序列比对', icon: 'blast' }
      },
      {
        path: '/sequence',
        component: () => import('@/views/pages/tools/sequence'),
        name: 'sequence',
        meta: { title: 'Sequence Fetch', title1: '序列提取', icon: 'sequence1' }
      },
      {
        path: '/OrthologousGene',
        component: () => import('@/views/pages/tools/OrthologousGene'),
        name: 'OrthologousGene',
        meta: { title: 'Orthologous Gene', title1: '同源基因', icon: 'go' }
      },
      {
        path: '/synvisio',
        component: () => import('@/views/pages/tools/synvisio'),
        name: 'synvisio',
        meta: { title: 'Synvisio', title1: '基因组共线性', icon: 'search1' }
      },
      {
        path: '/Jbrowse',
        component: () => import('@/views/pages/tools/Jbrowse'),
        name: 'Jbrowse',
        meta: { title: 'Jbrowse', title1: '基因组浏览器', icon: 'browse' },
        children: [
          {
            path: '/Jbrowse/genome1',//+res.data.data[key].label,
            component: () => import('@/views/pages/tools/Jbrowse'),
            name: 'genome1',
            meta: { title: 'genome1', title1: 'genome1', url: 'api/file/smart_omics/jbrowse/genome1/config.json', icon: 'genome' }
          },
          {
            path: '/Jbrowse/genome2',//+res.data.data[key].label,
            component: () => import('@/views/pages/tools/Jbrowse'),
            name: 'genome2',
            meta: { title: 'genome2', title1: 'genome2', url: 'api/file/smart_omics/jbrowse/genome2/config.json', icon: 'genome' }
          },
          {
            path: '/Jbrowse/genome3',//+res.data.data[key].label,
            component: () => import('@/views/pages/tools/Jbrowse'),
            name: 'genome3',
            meta: { title: 'genome3', title1: 'genome3', url: 'api/file/smart_omics/jbrowse/genome3/config.json', icon: 'genome' }
          },
        ]
      },
      {
        path: '/KEGG',
        component: () => import('@/views/pages/tools/KEGG'),
        name: 'KEGG',
        meta: { title: 'KEGG Enrichment', title1: 'KEGG富集', icon: 'kegg' }
      },
      {
        path: '/GO',
        component: () => import('@/views/pages/tools/GO'),
        name: 'GO',
        meta: { title: 'GO Enrichment', title1: 'GO富集', icon: 'go' }
      },
      {
        path: '/IDConversion',
        component: () => import('@/views/pages/tools/IDConversion'),
        name: 'IDConverter',
        meta: { title: 'IDConverter', title1: 'ID转化', icon: 'msa' }
      },
      {
        path: '/ReverseComplement',
        component: () => import('@/views/pages/tools/ReferenceSequence'),
        name: 'Reverse Complement',
        meta: { title: 'Reverse Complement', title1: '互补序列提取', icon: 'browse' }
      },
      {
        path: '/Primer',
        component: () => import('@/views/pages/tools/Primer'),
        name: 'PrimerDesigner',
        meta: { title: 'PrimerDesigner', title1: '引物设计', icon: 'go' }
      },

    ]
  },
  {
    path: '/downloadView',
    component: Abeam,
    redirect: 'download',
    name: 'downloadView',
    alwaysShow: true,
    meta: {
      title: 'Download',//标题
      title1: '下载',//标题
      icon: 'download'//图标
    },
    children: [
      {
        path: '/download',
        component: () => import('@/views/pages/download'),
        name: 'download',
        meta: { title: 'Download', title1: '下载', }
      },
    ]
  },
  {
    path: '/ContactView',
    component: Abeam,
    redirect: 'Contact',
    name: 'ContactView',
    alwaysShow: true,
    meta: {
      title: 'Contact',//标题
      title1: '联系我们',
      icon: 'contact'//图标
    },
    children: [
      {
        path: '/Contact',
        component: () => import('@/views/pages/Contact'),
        name: 'Contact',
        meta: { title: 'Contact', title1: '联系我们', }
      },
    ]
  },
  {
    path: '/detail',
    component: Abeam,
    redirect: 'detail',
    name: 'detail',
    alwaysShow: false,
    meta: {
      title: 'detail',//标题
      title1: '详情页',//标题
      icon: 'home',
    },
    children: [
      {
        path: '/detail',
        component: () => import('@/views/pages/detail'),
        name: 'detail',
        meta: { title: 'detail', title1: '详情页', }
      }
    ]
  },
  {
    path: '/function',
    component: Abeam,
    redirect: 'function',
    name: 'function',
    alwaysShow: false,
    meta: {
      title: 'function',//标题
      title1: '详情页',//标题
      icon: 'home',
    },
    children: [
      {
        path: '/Pathway',
        component: () => import('@/views/pages/omics/pathway'),
        name: 'Pathway',
        meta: { title: 'Pathway', title1: '信号通路', type: 1 }
      },
      {
        path: '/Pathway1',
        component: () => import('@/views/pages/omics/pathway1'),
        name: 'Pathway',
        meta: { title: 'Pathway', title1: '信号通路' }
      },
      {
        path: '/Basic',
        component: () => import('@/views/pages/omics/Basic'),
        name: 'Basic genomic information',
        meta: {
          title: 'Overview', title1: '基因组基本信息', type: 1
        }
      },
      {
        path: '/Function',
        component: () => import('@/views/pages/omics/function'),
        name: 'Function',
        meta: { title: 'Functional Annotation', title1: '基因功能', icon: 'jiyinxin', type: 1 }
      },
      /* {
        path: '/Function',
        component: () => import('@/views/pages/omics/search'),
        name: 'Function',
        meta: { title: 'Functional Annotation', title1: '基因功能',icon:'jiyinxin'}
      },
      {
        path: '/GeneLocation',
        component: () => import('@/views/pages/omics/GeneLocation'),
        name: 'GeneLocation',
        meta: { title: 'Gene Location', title1: '基因位置',icon:'jiyinxin'}
      }, */
      {
        path: '/Overview',
        component: () => import('@/views/pages/omics/Overview'),
        name: 'Overview',
        meta: { title: 'Overview', title1: '统计', icon: 'search1', type: 2 }
      },
      {
        path: '/CentromereDiagram',
        component: () => import('@/views/pages/omics/CentromereDiagram'),
        name: 'Centromere Diagram',
        meta: { title: 'Centromere Diagram', title1: '着丝粒展示', icon: 'dashboard', type: 1 }
      },
      {
        path: '/MicroCollinearity',
        component: () => import('@/views/pages/omics/MicroCollinearity'),
        name: 'MicroCollinearity',
        meta: { title: 'MicroCollinearity', title1: '基因微共线性', icon: 'dashboard', type: 1 }
      },
      {
        path: '/MacroCollinearity',
        component: () => import('@/views/pages/omics/MacroCollinearity'),
        name: 'MacroCollinearity',
        meta: { title: 'MacroCollinearity', title1: '基因共线性', icon: 'dashboard', type: 1 }
      },

      {
        path: '/Search',
        component: () => import('@/views/pages/omics/Search'),
        name: 'Search',
        meta: { title: 'Search', title1: '查询', icon: 'search1', type: 2 }
      },
      {
        path: '/ProteinExpression',
        component: () => import('@/views/pages/omics/ProteinExpression'),
        name: 'ProteinExpression',
        meta: { title: 'Protein Expression', title1: '蛋白表达', icon: 'search1', type: 3 }
      },
      {
        path: '/ProteinDifferences',
        component: () => import('@/views/pages/omics/ProteinDifferences'),
        name: 'ProteinDifferences',
        meta: { title: 'Protein Differences', title1: '蛋白差异', icon: 'search1', type: 3 }
      },
      {
        path: '/ProteinEnrichment',
        component: () => import('@/views/pages/omics/ProteinEnrichment'),
        name: 'ProteinEnrichment',
        meta: { title: 'Protein Enrichment', title1: '蛋白富集', icon: 'search1', type: 3 }
      },
      {
        path: '/InteractionAnalysis',
        component: () => import('@/views/pages/omics/InteractionAnalysis'),
        name: 'Network',
        meta: { title: 'Network', title1: '差异蛋白互作分析', icon: 'search1', type: 3 }
      },
      {
        path: '/Supersession',
        component: () => import('@/views/pages/omics/Supersession'),
        name: 'Supersession',
        meta: { title: 'Supersession', title1: '代谢', icon: 'search1', type: 4 }
      },
      {
        path: '/EpiOverview',
        component: () => import('@/views/pages/omics/EpiOverview'),
        name: 'Overview',
        meta: { title: 'Overview', title1: '统计', icon: 'search1', type: 5 }
      },
      {
        path: '/EpiSearch',
        component: () => import('@/views/pages/omics/EpiSearch'),
        name: 'Search',
        meta: { title: 'Search', title1: '查询', icon: 'search1', type: 5 }
      },
      {
        path: '/TranscriptFactor',
        component: () => import('@/views/pages/omics/TranscriptionFactor'),
        name: 'Transcription Factors',
        meta: { title: 'Transcription Factors', title1: '转录因子', icon: 'browse', type: 2 }
      },
      {
        path: '/MotifEnrichment',
        component: () => import('@/views/pages/omics/MotifEnrichment'),
        name: 'Motif Enrichment',
        meta: { title: 'Motif Enrichment', title1: 'Motif Enrichment', icon: 'browse', type: 2 }
      },
      {
        path: '/MotifPrediction',
        component: () => import('@/views/pages/omics/MotifPrediction'),
        name: 'Motif Prediction',
        meta: { title: 'Motif Prediction', title1: 'Motif Prediction', icon: 'browse', type: 2 }
      },


    ]
  },
  // 有下拉菜单，写在children中
  /*  {
     path: '/home1',
     component: Layout,
     // redirect: 'area',
     name: 'anti_channel',
     alwaysShow: true,
     meta: {
       title: '其他页面',//标题
       icon: 'home'//图标
     },
     children: [
       {
         path: '/home1/mm',
         component: () => import('@/views/login/index'),
         name: 'mm',
         meta: { title: '测试2', }
       },
       {
         path: '/home1/ceshi',
         component: () => import('@/views/login/index'),
         name: 'ceshi',
         meta: { title: '测试3', }
       },
     ]
   } */

  // {
  //	 path: '/404',
  //	 component: () => import('@/views/errorPage/404'),
  //	 hidden: true
  // },
  // {
  //	 path: '/401',
  //	 component: () => import('@/views/errorPage/401'),
  //	 hidden: true
  // }
]

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
})

export const asyncRouterMap = [
  /** When your routing table is too long, you can split it into small modules**/
]

